<template>
  <div class="ElevatorGO">
    <div class="centerBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="井道报告" name="1">
          <div class="container">
            <div class="tips">
              <span class="bold">查井道-井道报告 </span>
              <span class="color"
                >提示：先将"电梯报告"加入清单后,才能选择生成井道报告!</span
              >
            </div>
            <div class="listItem" v-if="JDBGList.length > 0">
              <div class="left">
                <div class="top">北京市朝阳区万豪酒店项目</div>
                <div class="center">
                  北京市朝阳区万豪酒店项目,北京市朝阳区万豪酒店项目,北京市朝阳区万豪酒店项目,北京市朝阳区万豪酒店项目,北京市朝阳区万豪酒店项目
                </div>
                <div class="bottom">创建时间：2022-2-9</div>
              </div>
              <div class="right">
                <i class="el-icon-share"></i>
                分享
              </div>
            </div>
            <div v-else style="text-align: center; padding-bottom: 20px">
              <el-empty description="暂无信息,赶紧去查询吧~"></el-empty>
              <el-button size="mini" type="primary">我的查井道</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      JDBGList: [{}], // 井道报告
    };
  },
};
</script>

<style>
</style>