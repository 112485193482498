<template>
  <div class="ElevatorGOReport">
    <div class="reportTitle">
      <i class="el-icon-arrow-left" @click="closeReport"></i>
      查井道报告
    </div>
    <div class="container">
      <div class="tips">
        <span class="bold">查井道-电梯报告 </span>
        <span class="color"
          >提示：只有电梯报告"加入清单",还可以生成"井道报告"呦！</span
        >
      </div>
      <div class="logo"></div>
      <div class="content">
        <div class="projectName">项目名称：北京市朝阳区万豪酒店项目</div>
        <div class="dataBox">
          <div class="dataItem" v-for="(item, index) in data" :key="index">
            <div class="itemTitle">
              业态：酒店
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-collapse-transition>
              <div v-show="item.show" class="itemTable">
                <table border>
                  <tr>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                  </tr>
                </table>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [{ show: true }],
    };
  },
  methods: {
    closeReport() {
      this.$emit("close", "close");
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #9c1d22;
.ElevatorGOReport {
  width: 1000px;
  margin: 0 auto;
  .reportTitle {
    text-align: center;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    color: $color;
    i {
      position: absolute;
      top: 3px;
      left: 0;
      color: #000;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .container {
    margin-top: 20px;
    background: #fff;
    padding-bottom: 10px;
    .tips {
      background: #fafafa;
      line-height: 2;
      padding-left: 10px;
      font-size: 14px;
      .bold {
        font-weight: bold;
        font-size: 16px;
      }
      .color {
        font-weight: bold;
        color: $color;
      }
    }
    .logo {
      height: 100px;
    }
    .content {
      width: 950px;
      margin: 0 auto;
      .projectName {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .dataBox {
        background: #eff2f6;
        padding: 10px;
        .dataItem {
          .itemTitle {
            background: $color;
            text-align: center;
            padding: 10px;
            color: #fff;
            font-weight: bold;
            position: relative;
            cursor: pointer;
            i {
              position: absolute;
              right: 0;
              top: 10px;
              font-weight: bold;
              font-size: 22px;
              right: 20px;
            }
          }
          .itemTable {
            background: #fff;
            overflow-x: auto;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            table {
              border-collapse: collapse;
              text-align: center;
              border-color: #ccc;
              width: 100%;
              table-layout: fixed;
              td {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
</style>