<template>
  <div style="height: 100%; background: #f7f7f7">
    <div class="navHearder">
      <div class="navLeft">
        <div class="upload">
          <img :src="imageUrl" class="avatar"/>
        </div>
        <div class="longText">
          <div class="textTop">
            <span>电梯GO</span>
            <i class="el-icon-star-off"></i>
          </div>
          <div class="textBottom">
            <el-radio-group v-model="activeName" size="small">
              <el-radio-button
                  v-for="item in textList"
                  :key="item.title"
                  :label="item.title"
              ><i :class="item.icon"></i>{{ item.title }}
              </el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="navRight">
        <div class="searchBar">
          <el-input
              ref="searchInput"
              class="searchInput"
              v-model="keyword"
              placeholder="搜索任务"
              clearable
          ></el-input>
          <el-dropdown trigger="click" @command="sortCommand">
            <el-button size="mini" type="text" class="textSearch"
            >按创建日期 <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>排序</el-dropdown-item>
              <el-dropdown-item command="date">创建日期</el-dropdown-item>
              <el-dropdown-item command="date">截至日期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div style="flex: 1">
        <ElevatorInquiry v-show="activeName == '电梯查询'"/>
        <WellReport v-show="activeName == '井道报告'"/>
      </div>
      <div class="footprint">
        <div class="footprintTitle">查询足迹</div>
        <el-timeline>
          <el-timeline-item timestamp="2022-2-9">
            北京市朝阳区万豪酒店项目
          </el-timeline-item>
          <el-timeline-item timestamp="2022-2-9">
            北京市朝阳区万豪酒店项目
          </el-timeline-item>
          <el-timeline-item timestamp="2022-2-9">
            北京市朝阳区万豪酒店项目
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <div v-show="JDDetailShow" class="ElevatorGO">
      <ElevatorGOReport @close="closeReport"/>
    </div>
  </div>
</template>

<script>
import ElevatorGOReport from "./ElevatorGOReport.vue";
import WellReport from "./WellReport.vue";
import ElevatorInquiry from "./ElevatorInquiry.vue";

export default {
  components: {
    ElevatorGOReport,
    WellReport,
    ElevatorInquiry,
  },
  data() {
    return {
      keyword: "",
      activeName: "电梯查询",
      textList: [
        {title: "电梯查询", icon: ""},
        {title: "井道报告", icon: ""},
        {title: "收藏清单", icon: ""},
        {title: "报告清单", icon: ""},
      ],
      imageUrl: "https://img1.imgtp.com/2023/02/14/zJ1QitNs.png", // logo
      editTitle: false, // 标题编辑
      projectName: "", // 标题名称
      show: false, // 展示添加信息
      autoWidth: 0,
      autoHeight: 0,
      JDDetailShow: false, // 详情展示
    };
  },
  mounted() {
  },
  methods: {
    // 排序
    sortCommand(e) {
      console.log(e);
    },
    // 页码切换
    handleTabsChange() {
      this.closeDT();
    },
    // 关闭井道报告详情
    closeReport(e) {
      this.JDDetailShow = false;
    },
  },
};
</script>

<style lang="scss">
$color: #d1100b;
.navHearder {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 70px;
  background: #f7f7f7;

  .navLeft {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding-left: 20px;

    .icon {
      cursor: pointer;
      width: 28px;
      font-size: 24px;
    }

    .upload {
      width: 65px;
      height: 59px;
      border-radius: 6px;

      .avatar {
        width: 65px;
        height: 65px;
        display: block;
      }
    }

    .longText {
      display: flex;
      flex-direction: column;
      height: 100%;

      .textTop {
        line-height: 40px;
        margin-left: 10px;
        overflow: hidden;
        height: 36px;

        i {
          cursor: pointer;
          margin-left: 10px;
          padding: 5px;
          border-radius: 5px;
          color: gray;
          transition: all 200ms linear 0s;

          &:hover {
            color: #333;
            background: #efefef;
          }
        }
      }

      .textBottom {
        flex: 1;
        display: flex;
        line-height: 28px;
        font-size: 14px;

        div {
          cursor: pointer;
          margin: 0 10px;
          color: gray;
          // transition: all 100ms linear 0s;
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .navRight {
    padding-right: 20px;

    .searchBar {
      height: 44px;
      display: flex;
      align-items: center;

      .searchInput {
        // display: none;
        margin-right: 10px;
        width: 300px;
      }
    }
  }
}

.ElevatorGO {
  padding: 20px;
  background: #f7f7f7;
  min-height: calc(100% - 70px);
  box-sizing: border-box;

  .centerBox {
    margin: 0 auto;

    .container {
      width: 100%;
      background: #fff;
      height: auto;
      overflow: hidden;

      .tips {
        background: #eee;
        line-height: 2;
        padding-left: 10px;
        font-size: 14px;

        .bold {
          font-weight: bold;
          font-size: 16px;
        }

        .color {
          font-weight: bold;
          color: $color;
        }
      }

      .form {
        padding: 20px;
        padding-bottom: 0;

        .top {
        }

        .bottom {
          border: 1px solid #ccc;
        }

        .ytBottom {
          display: flex;
          justify-content: center;
          line-height: 2;

          div {
            margin: 10px;
            background: #f2f3f5;
            width: 70px;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #fff;
          }

          .ytActive {
            border: 1px solid $color;
            color: $color;
            background: #fff;
          }
        }

        .dtppBottom {
          display: flex;

          div {
            margin: 0 10px;
            cursor: pointer;
          }

          .dtppActive {
            color: $color;
            font-weight: bold;
          }
        }
      }

      .dashed {
        border-top: 1px dashed #ccc;
        margin-bottom: 20px;
      }
    }

    .addBox {
      background: #eff2f6;
      height: 434px;
      margin: 10px;
      padding: 10px;
      overflow-y: auto;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        /* 滚动条里面小方块 */
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #e0e0e0;
      }

      &::-webkit-scrollbar-track {
        /* 滚动条里面轨道 */
        // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        // border-radius: 10px;
        background: transparent;
      }

      .addContent {
        // background: #fff;
        // padding: 20px;
        padding-bottom: 10px;

        .dtDetailTop {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            font-weight: bold;
            color: $color;
          }

          .right {
            display: flex;
            align-items: center;

            .rightItem {
              width: 60px;
              text-align: center;
            }
          }
        }

        .dtCode {
          display: flex;
          align-items: center;
          margin: 10px 0;
          height: 32px;

          .label {
            width: 90px;
            text-align: right;
            margin-right: 30px;
            color: #999;
            font-size: 14px;
          }
        }

        .dtDetailBottom {
          display: flex;

          .formContent {
            display: flex;
            color: #000;
            font-size: 15px;
            flex-wrap: wrap;

            div {
              margin: 0 10px;
              cursor: pointer;
            }

            .active {
              color: $color;
              font-weight: bold;
            }
          }
        }
      }
    }

    .listItem {
      padding: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #ccc;

      .left {
        flex: 1;
        margin-right: 20px;
        color: #999;

        .top {
          color: #000;
        }

        div {
          margin-bottom: 10px;
        }
      }

      .right {
        cursor: pointer;

        i {
          margin-right: 5px;
        }
      }
    }

    .tipsBottom {
      display: flex;
      justify-content: space-between;
      color: #999;
      align-items: center;
      padding: 10px 20px;
      border-top: 1px dashed #ccc;
    }
  }

  .rightBox {
    width: 0;
    overflow: hidden;
  }
}

.footprint {
  width: 200px;
  padding: 10px;
  padding-top: 60px;
  background: #f7f7f7;
  box-sizing: border-box;

  .footprintTitle {
    text-align: center;
    padding-bottom: 10px;
  }

  .footprintItem {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 3px #ccc;
    cursor: pointer;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .color {
      margin-top: 3px;
      color: #999;
    }
  }
}
</style>
