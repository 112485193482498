<template>
  <div class="ElevatorGO">
    <div class="centerBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="电梯查询" name="电梯查询">
          <div class="container">
            <div class="tips">
              <span class="bold">查井道-电梯报告 </span>
              <span class="color">提示："添加电梯"后才能查询！</span>
              选择"电梯品牌"可精准查询!
            </div>
            <el-form class="form" ref="form" :model="form" label-width="80px">
              <el-form-item label="业态">
                <div class="top">
                  <el-radio
                    v-for="item in YTList"
                    :key="item.Name"
                    v-model="form.yt"
                    :label="item.Name"
                    >{{ item.Name }}</el-radio
                  >
                </div>
                <div v-if="form.yt == '综合体'" class="bottom ytBottom">
                  <div
                    v-for="item in YTList"
                    :key="item.Id"
                    v-show="item.Name !== '综合体'"
                    :class="
                      YTSelectList.findIndex((im) => item.Id == im.Id) != -1
                        ? 'ytActive'
                        : ''
                    "
                    @click="YTSelect(item)"
                  >
                    {{ item.Name }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="电梯品牌">
                <div class="top">
                  <el-radio v-model="form.dtpp" label="all">全部品牌</el-radio>
                  <el-radio
                    v-for="item in DTPPList"
                    :key="item.Id"
                    v-model="form.dtpp"
                    :label="item.Id"
                    >{{ item.Value }}</el-radio
                  >
                </div>
                <div v-show="form.dtpp" class="bottom dtppBottom">
                  <div
                    :class="
                      DTPPSelectList.findIndex((im) => item.Id == im) != -1
                        ? 'dtppActive'
                        : ''
                    "
                    v-for="item in DTPPDetailList"
                    :key="item.Id"
                    @click="DTPPSelect(item.Id)"
                  >
                    {{ item.Name }}
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div class="dashed"></div>
            <div style="text-align: center; margin-bottom: 20px">
              <el-button
                size="mini"
                icon="el-icon-plus"
                type="primary"
                @click="addDT"
                >添加电梯</el-button
              >
            </div>
            <div class="addBox" v-if="DTDetail.length > 0">
              <div class="addContent">
                <div v-for="(DTItem, DTIndex) in DTDetail" :key="DTIndex">
                  <el-card>
                    <div slot="header">
                      <div class="dtDetailTop">
                        <div class="left">业态：{{ DTItem.yt }}</div>
                        <div class="right">
                          <div class="rightItem">
                            <el-button
                              size="mini"
                              @click="handleEdit(DTIndex)"
                              icon="el-icon-edit"
                              type="text"
                              >编辑</el-button
                            >
                          </div>
                          <div class="rightItem">
                            <el-popconfirm
                              title="确定删除吗？"
                              @confirm="handleDelete(DTIndex)"
                            >
                              <el-button
                                size="mini"
                                slot="reference"
                                icon="el-icon-delete"
                                type="text"
                                >删除</el-button
                              >
                            </el-popconfirm>
                          </div>
                          <div class="rightItem">
                            <el-button
                              size="mini"
                              icon="el-icon-arrow-down"
                              type="primary"
                              @click="DTItem.show = !DTItem.show"
                            ></el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-collapse-transition>
                      <div v-show="DTItem.show" style="padding: 10px">
                        <el-form
                          class="dtDetailBottom"
                          :ref="'form' + DTIndex"
                          :model="DTItem.ProElevatorGroups[0]"
                          label-width="110px"
                          :rules="rules"
                        >
                          <div style="flex: 1">
                            <el-form-item
                              v-if="DTItem.yt == '综合体'"
                              label="综合体业态"
                            >
                              <div class="formContent">
                                <div
                                  v-for="item in DTItem.ytList"
                                  :key="item.Id"
                                >
                                  {{ item.Name }}
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item label="电梯类型" prop="E_TypeName">
                              <div class="formContent">
                                <div
                                  v-for="item in DTLXList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_TypeName
                                      ? 'active'
                                      : ''
                                  "
                                  @click="
                                    formSelect(item, DTIndex, 'E_TypeName')
                                  "
                                >
                                  {{ item.Title }}
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item label="电梯编号" prop="E_GroupName">
                              <el-input
                                v-model="
                                  DTItem.ProElevatorGroups[0].E_GroupName
                                "
                              ></el-input>
                            </el-form-item>
                            <el-form-item
                              label="行程高度(m)"
                              prop="E_MaxLiftHeight"
                            >
                              <el-input
                                v-model="
                                  DTItem.ProElevatorGroups[0].E_MaxLiftHeight
                                "
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div style="flex: 1">
                            <el-form-item label="机房类型" prop="E_RoomType">
                              <div class="formContent">
                                <div
                                  v-for="item in JFLXList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_RoomType
                                      ? 'active'
                                      : ''
                                  "
                                  @click="
                                    formSelect(item, DTIndex, 'E_RoomType')
                                  "
                                >
                                  {{ item.Title }}
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item label="轿厢类型" prop="E_LiftCarType">
                              <div class="formContent">
                                <div
                                  v-for="item in JXLXList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_LiftCarType
                                      ? 'active'
                                      : ''
                                  "
                                  @click="
                                    formSelect(item, DTIndex, 'E_LiftCarType')
                                  "
                                >
                                  {{ item.Title }}
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item
                              label="开门方式"
                              prop="E_OpenDoorMode"
                            >
                              <div class="formContent">
                                <div
                                  v-for="item in KMFSList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_OpenDoorMode
                                      ? 'active'
                                      : ''
                                  "
                                  @click="
                                    formSelect(item, DTIndex, 'E_OpenDoorMode')
                                  "
                                >
                                  {{ item.Title }}
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item
                              label="对重类型"
                              prop="E_CounterWeight"
                            >
                              <div class="formContent">
                                <div
                                  v-for="item in DZLXList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_CounterWeight
                                      ? 'active'
                                      : ''
                                  "
                                  @click="
                                    formSelect(item, DTIndex, 'E_CounterWeight')
                                  "
                                >
                                  {{ item.Title }}
                                </div>
                              </div>
                            </el-form-item>
                            <!-- <el-form-item label="最大停站数">
                            <div class="formContent">
                              <div
                                v-for="item in ZDTZSList"
                                :key="item.Id"
                                @click="formSelect(item, DTIndex)"
                              >
                                {{ item.Title }}
                              </div>
                            </div>
                          </el-form-item> -->
                          </div>
                          <div style="flex: 1">
                            <el-form-item label="载重" prop="E_Load">
                              <div class="formContent">
                                <div
                                  v-for="item in ZZList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_Load
                                      ? 'active'
                                      : ''
                                  "
                                  @click="formSelect(item, DTIndex, 'E_Load')"
                                >
                                  {{ item.Title }}kg
                                </div>
                              </div>
                            </el-form-item>
                            <el-form-item label="梯速" prop="E_Speed">
                              <div class="formContent">
                                <div
                                  v-for="item in TSList"
                                  :key="item.Id"
                                  :class="
                                    item.Title ==
                                    DTItem.ProElevatorGroups[0].E_Speed
                                      ? 'active'
                                      : ''
                                  "
                                  @click="formSelect(item, DTIndex, 'E_Speed')"
                                >
                                  {{ item.Title }}m/s
                                </div>
                              </div>
                            </el-form-item>
                          </div>
                        </el-form>
                      </div>
                    </el-collapse-transition>
                  </el-card>
                </div>
              </div>
            </div>
            <div class="tipsBottom">
              <div>小提示：报告生成后，可在"查井道"查看</div>
              <el-button size="mini" type="primary" @click="generateReport"
                >GO</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeName: "电梯查询",
      form: {},
      rules: {
        E_TypeName: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_GroupName: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_MaxLiftHeight: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_RoomType: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_LiftCarType: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_OpenDoorMode: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_CounterWeight: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_Load: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
        E_Speed: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "change",
          },
        ],
      },
      YTList: [], // 业态列表
      YTSelectList: [], // 综合体业态选择列表
      DTPPList: [], // 电梯品牌分类
      DTPPDetailList: [], // 全部电梯品牌
      DTPPSelectList: [], // 电梯品牌选择列表
      DTDetail: [], // 电梯详情
      JXLXList: [], // 轿厢类型
      TSList: [], // 梯速
      JFLXList: [], // 机房类型
      ZZList: [], // 载重
      DTLXList: [], // 电梯类型
      KMFSList: [], // 开门方式
      DZLXList: [], // 对重类型
      ZDTZSList: [], // 最大停站数
    };
  },
  async mounted() {
    await this.getYT();
    await this.getDTPP();
    this.getJXLXList();
    this.getTSList();
    this.getJFLXList();
    this.getZZList();
    this.getDTLXList();
    this.getKMFSList();
    this.getDZLXList();
    this.getDZTZSList();
  },
  methods: {
    // 电梯详情选择
    formSelect(item, index, label) {
      console.log(item, index, label);
      this.$set(this.DTDetail[index].ProElevatorGroups[0], label, item.Title);
      console.log(this.DTDetail[index]);
      // 电梯编号拼接
      if (label == "E_TypeName") {
        this.$set(
          this.DTDetail[index].ProElevatorGroups[0],
          "E_GroupName",
          this.DTDetail[index].yt + item.Title + (index + 1) + "组"
        );
      }
    },
    // 编辑电梯
    handleEdit(index) {
      console.log(this.DTDetail);
    },
    // 删除电梯
    handleDelete(index) {
      this.DTDetail.splice(index, 1);
    },
    // 业态
    getYT() {
      this.$http
        .get("/Base_BasicData/Base_Format/GetFirstMenuList")
        .then((res) => {
          if (res.Success) {
            this.YTList = res.Data;
          }
        });
    },
    YTSelect(obj) {
      let idx = this.YTSelectList.findIndex((item) => item.Id == obj.Id);
      if (idx == -1) this.YTSelectList.push(obj);
      else this.YTSelectList.splice(idx, 1);
    },
    // 电梯品牌
    getDTPP() {
      this.$http
        .post("/Base_BasicData/Base_BrandGroup/GetDataListShowBrands", {})
        .then((res) => {
          if (res.Success) {
            this.DTPPList = res.Data;
          }
        });
      this.$http
        .post("/Base_BasicData/Base_Brand/GetDataList", {})
        .then((res) => {
          if (res.Success) {
            this.DTPPDetailList = res.Data;
          }
        });
    },
    // 生成井道报告
    generateReport() {
      this.DTDetail.forEach((item, index) => {
        this.$refs["form" + index][0].validate((valid) => {
          if (!valid) {
            return false;
          }
        });
      });
      // this.JDDetailShow = true;
    },
    DTPPSelect(Id) {
      let idx = this.DTPPSelectList.findIndex((item) => item == Id);
      if (idx == -1) this.DTPPSelectList.push(Id);
      else this.DTPPSelectList.splice(idx, 1);
    },
    // 添加电梯
    addDT() {
      if (!this.form.yt) return this.$message.error("请选择业态");
      if (this.form.yt == "综合体" && this.YTSelectList.length <= 0)
        return this.$message.error("请选择业态");
      if (!this.form.dtpp) return this.$message.error("请选择电梯品牌");
      if (this.DTPPSelectList.length <= 0)
        return this.$message.error("请选择电梯品牌");
      this.DTDetail.push({
        show: true,
        yt: this.form.yt,
        ytList: JSON.parse(JSON.stringify(this.YTSelectList)),
        ProElevatorGroups: [{}],
      });
    },
    // 轿厢类型
    getJXLXList() {
      this.$http
        .post("/Base_BasicData/Base_LiftcarType/GetDataList", {})
        .then((res) => {
          this.JXLXList = res.Data;
        });
    },
    // 梯速
    getTSList() {
      this.$http
        .post("/Base_BasicData/Base_Speed/GetDataList", {})
        .then((res) => {
          this.TSList = res.Data;
        });
    },
    // 机房类型
    getJFLXList() {
      this.$http
        .post("/Base_BasicData/Base_RoomType/GetDataList", {})
        .then((res) => {
          this.JFLXList = res.Data;
        });
    },
    // 载重
    getZZList() {
      this.$http
        .post("/Base_BasicData/Base_Weight/GetDataList", {})
        .then((res) => {
          this.ZZList = res.Data;
        });
    },
    // 电梯类型
    getDTLXList() {
      this.$http
        .post("/Base_BasicData/Base_ElevatorType/GetDataList", {})
        .then((res) => {
          this.DTLXList = res.Data;
        });
    },
    // 开门方式
    getKMFSList() {
      this.$http
        .post("/Base_BasicData/Base_Door/GetDataList", {})
        .then((res) => {
          this.KMFSList = res.Data;
        });
    },
    // 对重类型
    getDZLXList() {
      this.$http
        .post("/Base_BasicData/Base_CounterWeight/GetDataList", {})
        .then((res) => {
          this.DZLXList = res.Data;
        });
    },
    // 最大停站数
    getDZTZSList() {
      this.$http
        .post("/Base_BasicData/Base_MaxStop/GetDataList", {})
        .then((res) => {
          this.ZDTZSList = res.Data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body,
.el-main {
  padding: 0;
}
::v-deep .el-card__header {
  padding: 10px 20px;
}
</style>